import { useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  MenuItem,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import styles from './tools.module.css';
import { combineClassNames } from 'src/lib/helper';
import Breadcrumbs from '../common/BreadCrumbs';
import { OnboardingButton, OnboardingTextField } from '../onboarding/onboarding';
import CircleFlag from '../common/CircleFlag';
import InstanceDetail from '../common/InstanceDetail';
import InstanceSummary from './Summary';
import useGenericState from 'src/hooks/useGenericState';
import { getSupportedCloudProviders, getSupportedSupport, getSupportedVirtualMachine, managedToolDeploy } from 'src/api/managed-tools-api';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSupportedToolsApi } from 'src/api/supported-tools-api';
import { Modal } from '@mui/base';


const time = [
  {
    id: 0,
    code: '00:00',
  },
  {
    id: 1,
    code: '01:00',
  },
  {
    id: 2,
    code: '02:00',
  },
  {
    id: 3,
    code: '03:00'
  },
  {
    id: 4,
    code: '04:00'
  },
  {
    id: 5,
    code: '05:00'
  },
  {
    id: 6,
    code: '06:00'
  },
  {
    id: 7,
    code: '07:00'
  },
  {
    id: 8,
    code: '08:00'
  },
  {
    id: 9,
    code: '09:00'
  },
  {
    id: 10,
    code: '10:00'
  },
  {
    id: 11,
    code: '11:00'
  },
  {
    id: 12,
    code: '12:00'
  },
  {
    id: 13,
    code: '13:00'
  },
  {
    id: 14,
    code: '14:00'
  },
  {
    id: 15,
    code: '15:00'
  },
  {
    id: 16,
    code: '16:00'
  },
  {
    id: 17,
    code: '17:00'
  },
  {
    id: 18,
    code: '18:00'
  },
  {
    id: 19,
    code: '19:00'
  },
  {
    id: 20,
    code: '20:00'
  },
  {
    id: 21,
    code: '21:00'
  },
  {
    id: 22,
    code: '22:00'
  },
  {
    id: 23,
    code: '23:00'
  }
];

const day = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const PriceCard = ({ amount, currency = '', time = '' }) => {
  return (
    <div className={styles.priceCard}>
      <span className={styles.amount}>{amount}</span>&nbsp;&nbsp;
      {currency ?
        <span className={styles.rateWrapper}>{`${currency} / ${time}`}</span> : ''
      }
    </div>
  );
}


const InstanceSetup = () => {
  const initialState = {
    toolName: '',

    cloudProvider: '',

    geography: '',
    region: '',
    tab: 0,
    selectedZone: '',

    selectedConfigTab: 0,
    selectedSystemConfig: '',
    selectedOs: '',

    supportLevel: '',

    configureVolume: false,
    configureVolumeSize: '',

    enableAutoUpdate: false,
    autoUpdateDay: '',
    autoUpdateHour: '',

    formError: false,
    activeCloudProviders: [],
    systemConfigs: [],
    supportLevels: [],
    toolDetails: {
      id: '',
      name: '',
      extra_details: {
        logo: '',
        description: ''
      }
    },
    selectedSystemDetails: {
      "id": "",
      "name": "",
      "vcpu": "",
      "ram": "",
      "is_expandable_storage": "",
      "expandable_storage_id": "",
      "base_storage": "",
      "region": "",
      "price_per_hour": "",
      "is_active": "",
      "extra_details": {
        "os": {
          "name": "",
          "image": "",
          "version": "",
          "category": ""
        },
        "plan": "",
        "currency": ""
      }
    },
    selectedSupportDetails: {
      "id": "",
      "tool_id": "",
      "price_type": "",
      "price": "",
      "is_active": "",
      "created_at": "",
      "updated_at": "",
      "name": "",
      "is_default": "",
      "extra_details": {
        "currency": "",
        "features": []
      }
    },
    showModal: false,
    sshKeys: [],
    sshKeyInput: '',

  };

  const [state, setState] = useGenericState(initialState);

  const { toolName } = useParams();

  useEffect(() => {
    getSupportedCloudProviders().then((res) => {
      let geography = '';
      const dataBasedOnGeography = res.data.map((provider) => {
        return {
          ...provider,
          region_details: provider.region_details?.reduce((acc, curr) => {
            if (!acc[curr.geography]) {
              acc[curr.geography] = [];
            }
            if (!geography) {
              geography = curr.geography;
            }
            acc[curr.geography].push(curr);
            return acc;
          }, {})
        }
      });

      setState({
        activeCloudProviders: dataBasedOnGeography,
        cloudProvider: dataBasedOnGeography?.[0].name,
        selectedZone: dataBasedOnGeography?.[0].region_details?.[geography]?.[0].code,
        geography: geography
      });
    });

    getSupportedSupport({ toolName }).then((res) => {
      setState({ supportLevels: res.data });
    });

    fetchSupportedToolsApi({ toolName }).then((res) => {
      setState({
        toolDetails: res?.[0]
      })
    });

  }, []);

  const calculatePrice = () => {
    const { selectedSystemDetails, selectedSupportDetails } = state;

    const systemPrice = selectedSystemDetails.price_per_hour;
    const supportPrice = selectedSupportDetails.price;

    const systemPriceType = 'HOURLY';
    const supportPriceType = selectedSupportDetails.price_type;

    const convertToHourly = (price, priceType) => {
      switch (priceType) {
        case 'DAILY':
          return price / 24;
        case 'MONTHLY':
          return price / (24 * 30);
        case 'YEARLY':
          return price / (24 * 365);
        default:
          return price;
      }
    };

    const systemHourlyPrice = convertToHourly(systemPrice, systemPriceType);
    const supportHourlyPrice = convertToHourly(supportPrice, supportPriceType);

    const estimatedHourlyPrice = systemHourlyPrice + supportHourlyPrice;
    const estimatedDailyPrice = estimatedHourlyPrice * 24;
    const estimatedMonthlyPrice = estimatedDailyPrice * 30;
    return { estimatedHourlyPrice, estimatedMonthlyPrice, estimatedDailyPrice };
  };

  useEffect(() => {
    if (state.cloudProvider && state.selectedZone) {
      getSupportedVirtualMachine({ provider: state.cloudProvider, region: state.selectedZone }).then((res2) => {
        let selectedOs = '';
        const modifiedSystemConfig = res2.data.reduce((acc, curr) => {
          const os = curr?.extra_details?.os?.name;
          if (!acc[os]) {
            acc[os] = [];
          }
          acc[os].push(curr);
          if (!selectedOs) {
            selectedOs = os;
          }
          return acc;
        }, {});
        setState({ systemConfigs: modifiedSystemConfig, selectedOs });
      });
    }
  }, [state.selectedZone]);

  const onSubmitHandler = async () => {
    if (!state.toolName) {
      setState({ formError: true });
      return;
    }

    setState({ showModal: true });

  }

  const handleSSHKeySubmit = () => {
    setState({ sshKeys: [...state.sshKeys, state.sshKeyInput], sshKeyInput: '' });
  };

  const handleSSHKeyRemove = (index) => {
    setState({ sshKeys: state.sshKeys.filter((_, i) => i !== index) });
  };

  const navigate = useNavigate();

  const handleModalSubmit = async () => {
    setState({ showModal: false });
    if (state.sshKeys.length === 0) {
      throw new Error('SSH Keys are required');
    }

    const requestBody = {
      tool_id: state.toolDetails.id,
      ssh_keys: state.sshKeys,
      vm_details: {
        provider: state.cloudProvider,
        region: state.selectedZone,
        system_config: state.selectedSystemConfig,
        support_level: state.supportLevel,
        volume: state.configureVolume ? state.configureVolumeSize : '',
        auto_update: state.enableAutoUpdate,
        auto_update_time: state.autoUpdateHour,
        auto_update_day: state.autoUpdateDay
      },
      name: state.toolName,
    };

    await managedToolDeploy(requestBody);
    navigate(`/tool/${toolName}`);

  };

  const handleModalClose = () => {
    setState({ showModal: false });
  }

  return (
    <div className={combineClassNames('dashboard-wrapper', styles.container)}>
      <div>
        <Breadcrumbs path={[{
          label: 'Tools',
          link: '/'
        }, {
          label: toolName,
          link: `/tool/${toolName}`
        }, {
          label: 'Create New Instance',
          link: '/'
        }]} />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <div className={styles.title}>Tool setup</div>
          <div className={styles.section}>
            <h2 className={styles.sectionTitle}>Provide Tool Name</h2>
            <div className={styles.inputContainer}>
              <OnboardingTextField
                label='Tool name'
                type="text"
                value={state.toolName}
                onChange={(e) => setState({ toolName: e.target.value })}
                error={state.formError}
                helperText={state.formError && 'Tool Name is required'}
                variant="standard"
              />
            </div>
          </div>


          <div className={styles.section}>
            <h2 className={styles.sectionTitle}>Select Cloud Provider</h2>
            <div className={styles.cloudProviderContainer}>
              {state.activeCloudProviders.map((provider) => (
                <div
                  key={provider.name}
                  className={`${styles.cloudProviderButton} ${state.cloudProvider === provider.name ? styles.selected : ''}`}
                  onClick={() => setState({ cloudProvider: provider.name, geography: Object.keys(provider.region_details)[0] })}
                >
                  <img src={provider.logo_url} />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.section}>
            <h2 className={styles.sectionTitle}>Select Cloud Region</h2>
            <div className={styles.regionContainer}>
              <Tabs value={state.tab} className={styles.tabsWrapper}>
                {Object.keys(state.activeCloudProviders.find((cloudProvider) => state.cloudProvider === cloudProvider.name)?.region_details || {})?.map((reg, index) => (
                  <Tab
                    key={reg}
                    className={styles.regionCard}
                    label={reg}
                    onClick={() => setState({ tab: index, region: reg })}
                  />
                ))}
              </Tabs>
            </div>
            <div className={styles.availabilityZones}>
              {state.activeCloudProviders.find((cloudProvider) => state.cloudProvider === cloudProvider.name)?.region_details[state.geography]?.map((zone, index) => (
                <div
                  key={zone.name}
                  className={`${styles.zoneCard} ${state.selectedZone === zone.code ? styles.selected : ''}`}
                  onClick={() => setState({ selectedZone: zone.code })}
                >
                  <div className={styles.zoneLeftItem}>
                    <div className={styles.zoneTitle}>{zone.zone}</div>
                    <div className={styles.zoneName}>{zone.name}</div>
                  </div>
                  <div className={styles.zoneFlag}>
                    <CircleFlag flag={zone.geography_logo} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.section}>
            {Object.keys(state.systemConfigs || {}).length ?
              <>
                <h2 className={styles.sectionTitle}>Select system configuration</h2>
                <div className={styles.regionContainer}>
                  <Tabs value={state.selectedConfigTab} className={styles.tabsWrapper}>
                    {Object.keys(state.systemConfigs || {}).map((systemOs, idx) => (
                      <Tab
                        key={systemOs}
                        className={styles.regionCard}
                        label={systemOs}
                        onClick={() => {
                          setState({ selectedOs: systemOs });
                        }
                        }
                      />
                    ))}
                  </Tabs>
                  <div className={styles.availabilityZones}>
                    {(state.systemConfigs || {})?.[state.selectedOs] ? state.systemConfigs[state.selectedOs]?.map((system, index) => (
                      <div className={system.name === state.selectedSystemConfig ? styles.parentSelected : ''} onClick={() => { setState({ selectedSystemConfig: system.name, selectedSystemDetails: system }) }} style={{
                        width: '100%'
                      }}>
                        <InstanceDetail key={index} title={system.name} logoUrl='' config={[
                          `${system?.vcpu} CPU`,
                          `${system?.ram} GB RAM`,
                          `${system?.base_storage} GB Storage`,
                          `${system?.price_per_hour} ${system?.extra_details?.currency} per hour`
                        ]} />
                      </div>
                    )) : <> </>}
                  </div>
                </div>
              </> : <></>
            }
          </div>

          <div className={styles.section}>
            <h2 className={styles.sectionTitle}>Select support</h2>
            <div className={styles.availabilityZones}>
              {state.supportLevels.map((support, index) => (
                <div className={support.name === state.supportLevel ? styles.parentSelected : ''} onClick={() => { setState({ supportLevel: support.name, selectedSupportDetails: support }) }} style={{
                  width: '100%'
                }}>
                  <InstanceDetail key={index} title={support.name} logoUrl='' config={support?.extra_details?.features || []} rightSideComponent={<PriceCard amount={support.price} currency={support?.extra_details?.currency} time={support.price_type} />} />
                </div>
              ))}
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.toggleContainer}>
              <h2 className={styles.sectionTitle}>Configure Network Volume</h2>
              <Switch onClick={() => { setState({ configureVolume: !state.configureVolume }) }} value={state.configureVolume} />
            </div>
            {state.configureVolume ? <div className={styles.volumeSize}>
              <OnboardingTextField
                label='Enter Volume Size'
                type="text"
                value={state.configureVolumeSize}
                onChange={(e) => setState({ configureVolumeSize: e.target.value })}
                error={state.formError}
                helperText={state.formError && 'Volume Size is required'}
                variant="standard"
              />
            </div> : <></>}
          </div>

          <div className={styles.section}>
            <div className={styles.toggleContainer}>
              <h2 className={styles.sectionTitle}>Software updates</h2>
              <Switch onClick={() => { setState({ enableAutoUpdate: !state.enableAutoUpdate }) }} value={state.enableAutoUpdate} />
            </div>
            {state.enableAutoUpdate ? <div className={styles.volumeSize}>
              <OnboardingTextField
                label='Time'
                select
                value={state.configureVolumeSize}
                onChange={(e) => setState({ configureVolumeSize: e.target.value })}
                error={state.formError}
                helperText={state.formError && 'Time is required'}
                variant="standard"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '20rem',
                        minHeight: '20rem',
                      },
                    },
                  }
                }}
              >
                {time.map((timeDetails) => (
                  <MenuItem key={timeDetails.id} value={timeDetails.id}>
                    {timeDetails.code}
                  </MenuItem>
                ))}
              </OnboardingTextField>

              <OnboardingTextField
                label='Day'
                select
                value={state.autoUpdateDay}
                onChange={(e) => setState({ autoUpdateDay: e.target.value })}
                error={state.formError}
                helperText={state.formError && 'Day is required'}
                variant="standard"
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: '20rem',
                        minHeight: '20rem',
                      },
                    },
                  }
                }}
              >
                {day.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </OnboardingTextField>

            </div> : <></>}
          </div>

          <div className={styles.section}>
            <div className={styles.toggleContainer}>
              <h2 className={styles.sectionTitle}>System updates</h2>
              <Switch />
            </div>
          </div>
        </div>

        <div className={styles.rightWrapper}>
          <InstanceSummary
            toolName={state?.toolDetails?.name}
            toolLogo={state?.toolDetails?.extra_details?.logo}
            region={`${state.geography} (${state.selectedZone})`}
            provider={state.cloudProvider}
            plan={state.selectedSystemConfig}
            support={state.supportLevel}
            estimatedHourlyPrice={calculatePrice().estimatedHourlyPrice}
            estimatedMonthlyPrice={calculatePrice().estimatedMonthlyPrice}
            cloudProviderLogo={state.activeCloudProviders.find((cloudProvider) => state.cloudProvider === cloudProvider.name)?.logo_url}
            onSubmitHandler={onSubmitHandler}
            planDetails={state.selectedSystemConfig ? [
              `${state.selectedSystemDetails?.vcpu} CPU`,
              `${state.selectedSystemDetails?.ram} GB RAM`,
              `${state.selectedSystemDetails?.base_storage} GB Storage`,
              `${state.selectedSystemDetails?.price_per_hour} ${state.selectedSystemDetails?.extra_details?.currency} per hour`
            ] : []}
            currency={state.selectedSystemConfig ? state.selectedSystemDetails?.extra_details?.currency : state.selectedSupportDetails?.extra_details?.currency}
          />
        </div>
      </div>

      <Dialog
        open={state.showModal}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.modal}
      >
        <DialogTitle id="alert-dialog-title">
          Enter SSH Keys
        </DialogTitle>
        <Box className={styles.modalBox}>
          <div className={styles.headerWrapper}>
            <OnboardingTextField
              label='SSH Key'
              type="text"
              value={state.sshKeyInput}
              onChange={(e) => setState({ ...state, sshKeyInput: e.target.value })}
              variant="standard"
            />
            <Button variant="contained" color="primary" onClick={handleSSHKeySubmit} className={styles.addSSHKeyButton}>
              + Add
            </Button>
          </div>
          <div className={styles.sshKeyList}>
            {state.sshKeys.map((key, index) => (
              <>
                <div key={index} className={styles.sshKeyItem}>
                  <div>{key}</div>
                  <Button variant="outlined" color="secondary" onClick={() => handleSSHKeyRemove(index)} className={styles.removeSSHKeyButton}>
                    Remove
                  </Button>
                </div>
                {
                  index !== state.sshKeys.length - 1 ? <div className={styles.divider}></div> : <></>
                }
              </>
            ))}
          </div>

          <Button variant="contained" color="primary" onClick={handleModalSubmit}>
            Done
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};

export default InstanceSetup;