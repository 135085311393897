import './logo.css';
import React from 'react';
import Grid from '@mui/material/Grid';
import logo from '../../assets/logo.svg';
import { useNavigate } from 'react-router-dom';

interface IProps {
  allowHomeRedirection?: boolean;
}

function Logo(props: IProps) {
  const navigate = useNavigate();
  return (
    <Grid container onClick={() => {
      if (props.allowHomeRedirection) {
        navigate('/');
      }
    }} className='logo-container'>
      <img src={logo} className="logo" alt="logo" />
      <p className="logo-org-name-text">QUICK TOOLS</p>
    </Grid>
  );
}

export default Logo;
