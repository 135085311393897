import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getAuthenticatedUserHeaders, getErrorMessage } from './helper';
import apiProvider from './provider';

export const fetchUserAndRoles = async (search?: string, page_last_id?: string) => {
    try {
      const response = await apiProvider({
        method: 'get',
        url: config.coreBackendUrl + API_ENDPOINTS.ROLE.FETCH_USERS_ROLE,
        params: {
            search,
            page_last_id
        },
        headers: {
          ...getAuthenticatedUserHeaders(),
          'Content-Type': 'application/json',
        },
        
      });
  
      if (response.status === 200) {
        const data = response.data;
        return data?.data;
      } else {
        const errorMessage = response.data?.error?.message || 'Network error!';
        throw new Error(errorMessage);
      }
    } catch (error) {
      throw error;
    }
  };

  export const fetchRoles = async (params) => {
    try {
      const response = await apiProvider({
        method: 'get',
        url: config.coreBackendUrl + API_ENDPOINTS.ROLE.FETCH_ROLES,
        params,
        headers: {
          ...getAuthenticatedUserHeaders(),
          'Content-Type': 'application/json',
        },
        
      });
  
      if (response.status === 200) {
        const data = response.data;
        return {roles: data?.roles, showNextPage: data?.show_next_page};
      } else {
        const errorMessage = response.data?.error?.message || 'Network error!';
        throw new Error(errorMessage);
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      throw new Error(errorMessage);
    }
  };

export const createRole = async (role: any) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + API_ENDPOINTS.ROLE.CREATE_ROLE,
      data: role,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const fetchRole = async (role_id: string) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.ROLE.FETCH_ROLES + '/' + role_id,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};