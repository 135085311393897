export const LOCAL_STORAGE_ITEM_ENUM = {
  USER_ID: 'userId',
  ACCESS_TOKEN: 'accessToken',
  ORGANISATION_ID: 'organisationId',
  USER_DETAILS: 'userDetails'
}

export const getAuthenticatedUserHeaders = () => {
  const toRet = {
    'user-id': localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.USER_ID),
    'access-token': localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.ACCESS_TOKEN),
  };

  if (localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.ORGANISATION_ID)) {
    toRet['organisation-id'] = localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.ORGANISATION_ID);
  }

  return toRet;
};


export const authenticationHeadersPresent = () => {
  return (localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.USER_ID) && localStorage.getItem(LOCAL_STORAGE_ITEM_ENUM.ACCESS_TOKEN) || false);
}

export const clearUserCredential = () => {
  localStorage.removeItem(LOCAL_STORAGE_ITEM_ENUM.USER_ID);
  localStorage.removeItem(LOCAL_STORAGE_ITEM_ENUM.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_ITEM_ENUM.ORGANISATION_ID);
};

export const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = err.message || 'Could not connect. Please check your internet connection';
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;
    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};

export const REQUEST_TYPE_ENUM = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete'
}