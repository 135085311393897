import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import styles from './toolsDetails.module.css';
import InstanceDetail from '../common/InstanceDetail';
import Breadcrumbs from '../common/BreadCrumbs';
import { capitalizeWords, combineClassNames } from 'src/lib/helper';
import { OnboardingButton } from '../onboarding/onboarding';
import { Add } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { getManagedToolsInstances } from 'src/api/managed-tools-api';
import useGenericState from 'src/hooks/useGenericState';

const DEFAULT_STATE = {
  activeInstance: [],
  inactiveInstance: []
}
function ToolsDetails() {
  const [instanceData, setInstanceData] = useGenericState(DEFAULT_STATE);


  const { toolName } = useParams(); 
  useEffect(()=> {
    (async () => {
      const res = await getManagedToolsInstances(toolName);
      const activeInstances = res.data?.filter((item) => item.is_active);
      const inactiveInstances = res.data?.filter((item) => !item.is_active);
      setInstanceData({
        activeInstance: activeInstances,
        inactiveInstance: inactiveInstances
      });
    })()
  }, [toolName]);

  return (
    <div className={combineClassNames('dashboard-wrapper', styles.dashboardWrapper)}>
      <div >
        <Breadcrumbs path={[{
          label: 'Managed Tools',
          link: '/'
        }, {
          label: `${toolName}`,
          link: `/tool/${toolName}`
        }]} />
        <div className={styles.wrapper}>
          <div className={styles.toolHeader}>{capitalizeWords(toolName)}</div>
          <div className={styles.onboardingButton}> <OnboardingButton href={`/tool/${toolName}/deploy`}> <Add className={styles.addButton} /> Deploy </OnboardingButton> </div>
        </div>
      </div>
      <div>
        <div className={styles.toolSubHeading}>Active Tools</div>
        <Stack spacing={2} className="instance-container">
          {instanceData?.activeInstance.length ? instanceData.activeInstance.map((instance, index) => (
            <InstanceDetail
              key={index}
              title={instance.name}
              logoUrl={instance.logoUrl}
              config={instance.config}
            />
          )) : <div className={styles.noInstance}>No active instances</div>}
        </Stack>
      </div>
      <div>
        <div className={styles.toolSubHeading}>Inactive Tools</div>
        <Stack spacing={2} className="instance-container">
        {instanceData?.inactiveInstance.length ? instanceData.activeInstance.map((instance, index) => (
            <InstanceDetail
              key={index}
              title={instance.name}
              logoUrl={instance.logoUrl}
              config={instance.config}
            />
          )) : <div className={styles.noInstance}>No Inactive instances</div>}
        </Stack>
      </div>
    </div>
  );
}

export default ToolsDetails;
