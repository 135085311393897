import React, { useEffect } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Checkbox,
    Grid,
} from '@mui/material';
import styles from './userProfile.module.css';
import { combineClassNames } from 'src/lib/helper';
import { OnboardingButton, OnboardingTextField } from '../onboarding/onboarding';
import { FormControl } from '@mui/base';
import CountrySelect from '../common/CountrySelect';
import { updateUserDetails } from 'src/api/users';
import { useNotification } from '../notification/notification-provider';
import { AuthContext } from '../login/auth-provider';

const UserProfile = () => {
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [firstName, setFirstName] = React.useState('');
    const [formError, setFormError] = React.useState(false);
    const [lastName, setLastName] = React.useState('');
    const [countryCode, setCountry] = React.useState('');
    const [state, setState] = React.useState('');
    const [city, setCity] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [isActive, setIsActive] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const { showNotification } = useNotification();
    const { setUserDetails, userDetails } = React.useContext(AuthContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!firstName || !lastName || !countryCode || !phone) {
            setFormError(true);
            setIsLoading(false);
            return;
        }

        // Submit form data to backend
        try {
            const response = await updateUserDetails({ firstname: firstName, lastname: lastName, country_code: countryCode, phone, state, city });
            showNotification('Profile updated successfully', 'success');
            setUserDetails({
                ...userDetails,
                firstname: firstName,
                lastname: lastName,
                country_code: countryCode,
                phone: phone
            });
        } catch (error: any) {
            setIsLoading(false);
            showNotification(error.message, 'error');
        }
    };

    useEffect(() => {
        if (userDetails) {
            setFirstName(userDetails.firstname);
            setLastName(userDetails.lastname);
            setCountry(userDetails.country_code);
            setPhone(userDetails.phone);
            setState(userDetails.state);
            setCity(userDetails.city);
            setIsActive(userDetails.is_active);
        }
    }, [userDetails])

    return (
        <div className={combineClassNames('dashboard-wrapper', styles.dashboardWrapper)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <div className={styles.dashboardHeader}>
                    <span>
                        User profile
                    </span>
                    {isActive ? <span className={styles.userActive}> Active </span> : <></>}
                </div>
                <OnboardingButton style={{
                    padding: '0.75rem 1.25rem',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontWeight: 600,
                    letterSpacing: '-0.01rem',
                    maxWidth: '8.5rem'
                }} onClick={handleSubmit}> Save Details </OnboardingButton>
            </Box>

            <Tabs value={value} onChange={handleChange} className={styles.tabsWrapper}>
                <Tab label="User info" />
                <Tab label="Account" disabled />
            </Tabs>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={{ lg: 4, xl: 8 }} className={styles.grid}>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <OnboardingTextField
                                label="First Name"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                error={formError && !firstName}
                                helperText={formError && !firstName && 'First name is required'}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <OnboardingTextField
                                label="Last Name"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                error={formError && !lastName}
                                helperText={formError && !lastName && 'Last name is required'}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <CountrySelect countryCode={countryCode} setCountry={setCountry} fieldsToShow={['flag', 'dial_code', 'name']} showError={formError} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <OnboardingTextField
                                label="Phone"
                                type="number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                error={formError && !phone}
                                helperText={formError && !phone && 'Phone is required'}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <OnboardingTextField
                                label="City"
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                error={formError && !city}
                                helperText={formError && !city && 'City is required'}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl style={{ width: '100%' }}>
                            <OnboardingTextField
                                label="State"
                                type="text"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                error={formError && !state}
                                helperText={formError && !state && 'State is required'}
                                variant="standard"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <div className={styles.profileNewsletterContainer}>
                    <Checkbox defaultChecked color="default" />
                    <div>
                        Newsletter (Receive latest news about Quicktools features,
                        supported softwares, tips.)
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UserProfile;