import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getAuthenticatedUserHeaders, getErrorMessage, REQUEST_TYPE_ENUM } from './helper';
import apiProvider from './provider';

export const fetchUsersRole = async (params: { search?: string, page_last_id?: string }) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.ROLE.FETCH_USERS_ROLE,
      params,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return {
        data: data?.data,
      };
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};


export const fetchUsers = async (params: { search?: string, page_last_id?: string }) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.USER.FETCH_USERS,
      params,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return {
        data: data?.users,
        showNextPage: data?.show_next_page,
      };
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};


export const fetchCurrentUserDetails = async () => {
  try {
    const response = await apiProvider({
      method: REQUEST_TYPE_ENUM.GET,
      url: config.coreBackendUrl + API_ENDPOINTS.USER.FETCH_DETAILS,
      headers: {
        ...getAuthenticatedUserHeaders()
      }
    });
    return response.data;

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const updateUserDetails = async (params = {}) => {
  try {
    const response = await apiProvider({
      method: REQUEST_TYPE_ENUM.POST,
      url: config.coreBackendUrl + API_ENDPOINTS.USER.UPDATE,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthenticatedUserHeaders()
      },
      data: params
    });
    return response.data;

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const resetPassword = async (params = {}) => {
  try {
    const response = await apiProvider({
      method: REQUEST_TYPE_ENUM.POST,
      url: config.coreBackendUrl + API_ENDPOINTS.USER.RESET_PASSWORD,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthenticatedUserHeaders()
      },
      data: params
    });
    return response.data;

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const resetPasswordLinkSend = async (params = {}) => {
  try {
    const response = await apiProvider({
      method: REQUEST_TYPE_ENUM.POST,
      url: config.coreBackendUrl + API_ENDPOINTS.USER.RESET_PASSWORD_LINK_GENERATE,
      headers: {
        'Content-Type': 'application/json',
        ...getAuthenticatedUserHeaders()
      },
      data: params
    });
    return response.data;

  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}