import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  OnboardingLayout,
  OnboardingButton,
  OnboardingTextField,
} from '../onboarding/onboarding';
import { useNotification } from '../notification/notification-provider';
import Logo from '../common/logo';
import styles from './reset-password.module.css';

// Assume this function is implemented in your API
import { preSignup } from 'src/api/signup-api';
import { resetPassword } from 'src/api/users';

const passwordConstraintHandlerMap = {
  is_special_character_required: (password) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
  is_digit_required: (password) => /[0-9]+/.test(password),
  is_upper_case_required: (password) => /[A-Z]+/.test(password),
  is_lower_case_required: (password) => /[a-z]+/.test(password),
  min_password_length: (password, length) => password.length >= length,
};

function PasswordReset() {
  const [newPassword, setnewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setnewPasswordError] = useState(false);
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordConstraints, setPasswordConstraints] = useState([]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  
  
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  React.useEffect(() => {
    async function fetchPasswordConstraints() {
      if (passwordConstraints.length) {
        return;
      }
      try {
        const response = await preSignup();
        setPasswordConstraints(response?.data?.password_constraints || []);
      } catch (err) {
        console.log(err);
      }
    }

    fetchPasswordConstraints();
  }, [passwordConstraints]);

  const handleconfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value;
    setnewPassword(confirmPasswordValue);
    
    const updatedConstraints = passwordConstraints.map((constraint) => ({
      ...constraint,
      success: passwordConstraintHandlerMap[constraint.id](confirmPasswordValue, constraint.val),
    }));
    
    setPasswordConstraints(updatedConstraints);
  };

  const handleResetPassword = async (event) => {

    event.preventDefault();
    setIsLoading(true);

    let isError = false;

    if (!newPassword) {
      setnewPasswordError(true);
      isError = true;
      return;
    } else {
      setnewPasswordError(false);
    }

    if (!confirmPassword) {
      setconfirmPasswordError(true);
      isError = true;
      return;
    } else {
      setconfirmPasswordError(false);
    }

    if (confirmPassword !== newPassword) {
      setconfirmPasswordError(true);
      showNotification('Passwords do not match', 'error');
      isError = true;
      return
    }

    if (isError) {
      setIsLoading(false);
      return;
    }

    try {
      await resetPassword({
        token,
        password: newPassword,
      });
      showNotification('Password reset successfully', 'success');
      navigate('/login');
    } catch (error: any) {
      showNotification(error.message, 'error');
      setIsLoading(false);
    }

  };

  return (
    <div>
      <OnboardingLayout
        formHeight="90%"
        jsx={
          <div className={styles.resetFormContainer}>
            <div className="org-name-container">
              <Logo />
            </div>
            <form onSubmit={handleResetPassword}>
              <Stack spacing={{ xs: 1, sm: 2, md: 2, lg: 4 }}>
                <div className={styles.backIcon}>
                  <ArrowBackIosNewIcon
                    fontSize="small"
                    onClick={() => navigate('/login')}
                  />
                </div>
                <div className={styles.headerWrapper}>
                  <h2 className={styles.resetPassword}>
                    Reset Password
                  </h2>
                  <p className={styles.onboardingSubText}>
                    Please create a new password for your account
                  </p>
                </div>
                <div className={styles.formItems}>
                  <OnboardingTextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={handleconfirmPasswordChange}
                    error={newPasswordError}
                    helperText={newPasswordError && 'New password is required'}
                    variant="standard"
                  />
                  <OnboardingTextField
                    label="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={confirmPasswordError}
                    helperText={confirmPasswordError ? confirmPassword ? 'Confirm Password and New Password should be equal' : 'Confirm password is required': ''}
                    variant="standard"
                  />
                </div>
              </Stack>
              <div className="password-constraint-container">
                {passwordConstraints.map((item) => (
                  <div key={item.id} className="password-constraint-item">
                    {item.success ? (
                      <CheckCircleIcon sx={{ color: 'green' }} />
                    ) : (
                      <CheckCircleIcon sx={{ color: '#E5E6EA' }} />
                    )}
                    <p className="password-constraint-item-text">
                      {item.pretty_name}
                    </p>
                  </div>
                ))}
              </div>
              <div className={styles.buttonWrapper}>
                <OnboardingButton
                  type="submit"
                  variant="contained"
                  disabled={passwordConstraints.filter((item) => !item.success).length > 0}
                >
                  Continue
                </OnboardingButton>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
}

export default PasswordReset;