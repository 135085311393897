import React from 'react';
import styles from './CircleFlag.module.css';
import { combineClassNames } from 'src/lib/helper';

const CircleFlag = ({ flag, size = 'medium' }) => {
  return (
    <div className={combineClassNames(styles.circleFlag, styles[size])}>
      <img src={flag}/>
    </div>
  );
};

export default CircleFlag;