import React from 'react';
import './onboarding.css';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { OnboardingLayoutProps } from './types';
import OnboardingImage from '../../assets/images/login-signup.png';
export const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  jsx,
  formHeight,
}) => (
  <div>
    <div className="container">
      <div className="left-container">
        <img className="image-container" src={OnboardingImage} alt='Onboarding' />
        <div className="onboarding-image-text-container">
          <h1>Maximizing the developer’s productivity </h1>
          <h3>
            Streamline deployment. Cloud or on-premise. Scalable and secure.
          </h3>
        </div>
      </div>
      <div className="right-container">
        <div className="right-form-container" style={{ height: formHeight }}>
          {jsx}
        </div>
      </div>
    </div>
  </div>
);
export const OnboardingButton = styled(Button)({
  background: '#4B4EFC',
  // border: '8px',
  padding: '0px 25px',
  height: '3.12rem',
  width: '100%',
  color: 'white',
  '&:hover': {
    background: '#4B4EFC',
  },
  'text-transform': 'unset',
  fontSize: '1.125rem',
  'font-weight': '500',
  'letter-spacing': '-0.01rem',
  borderRadius: '8px',
  fontWeight: '600',
});

export const OnboardingTextField = styled(TextField)({
  '&& .MuiInput-underline::before': {
    borderBottom: '0.5px solid var(--primaryBorder)',
  },
  '&& .Mui-focused::before': {
    borderBottom: '0.5px solid var(--primaryBorder)',
  },
  '& label.Mui-focused':{
    color: 'var(--lightTextColor)',
    fontWeight: '600',
    fontSize: '0.85rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.02rem'
  },
  '& label[data-shrink="true"]': {
    color: 'var(--lightTextColor)',
    fontWeight: '600',
    fontSize: '0.85rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.02rem'
  },
  '& label[data-shrink="false"]': {
    color: 'var(--lightTextColor)',
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.02rem'
  },
  '& .MuiAutocomplete-inputRoot': {
    backgroundColor: 'var(--white)',
  },
  '&& .MuiInput-underline:hover::before': {
    borderBottom: '0.5px solid var(--primaryBorder)',
  },
  '&& input.MuiInputBase-input.MuiInput-input': {
    fontWeight: '500',
    color: 'var(--darkTextColor)',
  },
  marginTop: '-8px',
  width: '100%',

});