import React, { useEffect, useRef } from 'react';
import styles from './profile-form.module.css';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
// import { updateProfile } from '../../api/profile-api';
import { useNavigate } from 'react-router-dom';
import {
    OnboardingLayout,
    OnboardingButton,
    OnboardingTextField,
} from '../onboarding/onboarding';
import { useNotification } from '../notification/notification-provider';
import Logo from '../common/logo';
import CountryList from '../../lib/CountryList.json';
import { updateUserDetails } from 'src/api/users';
import { AuthContext } from '../login/auth-provider';

function ProfileForm() {
    const formRef = useRef();
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [countryCode, setCountry] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [formError, setFormError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [searchCountry, setSearchCountry] = React.useState('');

    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { setUserDetails, userDetails } = React.useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!firstName || !lastName || !countryCode || !phone) {
            setFormError(true);
            setIsLoading(false);
            return;
        }

        // Submit form data to backend
        try {
            const response = await updateUserDetails({ firstname: firstName, lastname: lastName, country_code: countryCode, phone });
            showNotification('Profile updated successfully', 'success');
            setUserDetails({
                ...userDetails,
                firstname: firstName,
                lastname: lastName,
                country_code: countryCode,
                phone: phone
            });
            navigate('/organisation-list');
        } catch (error: any) {
            setIsLoading(false);
            showNotification(error.message, 'error');
        }
    };

    useEffect(() => {
        if (userDetails) {
            setFirstName(userDetails.firstname);
            setLastName(userDetails.lastname);
            setCountry(userDetails.country_code);
            setPhone(userDetails.phone);
        }
    }, [userDetails])

    const filteredCountries = CountryList.filter(country =>
        country.name.toLowerCase().includes(searchCountry.toLowerCase())
    ) || [];

    return (
        <div>
            <OnboardingLayout
                formHeight=""
                jsx={
                    <div className={styles.profileFormWrapper}>
                        <div className='logo-container'>
                            <Logo />
                        </div>
                        <div className={styles.profileFormContent}>
                            <div>
                                <h2 className="onboarding-main-text">User Details</h2>
                                <p className={styles.profileSubText}>
                                    Please provide your information
                                </p>
                            </div>
                            <div>
                                <form ref={formRef} onSubmit={handleSubmit}>
                                    <Grid container spacing={{ lg: 4, xl: 8 }}>
                                        <Grid item xs={12}>
                                            <FormControl style={{ width: '100%' }}>
                                                <OnboardingTextField
                                                    label="First Name"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    error={formError && !firstName}
                                                    helperText={formError && !firstName && 'First name is required'}
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl style={{ width: '100%' }}>
                                                <OnboardingTextField
                                                    label="Last Name"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    error={formError && !lastName}
                                                    helperText={formError && !lastName && 'Last name is required'}
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} className={styles.phoneWrapper}>
                                            <FormControl style={{ width: '30%' }}>
                                                <OnboardingTextField
                                                    select
                                                    label="Country"
                                                    value={countryCode}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    error={formError && !countryCode}
                                                    helperText={formError && !countryCode ? 'Country is required' : ''}
                                                    variant="standard"
                                                    className={styles.countryDropdown}
                                                    placeholder='Country'
                                                    SelectProps={{
                                                        renderValue: (value) => value ? `${CountryList.find(c => c.code === value)?.flag} ${CountryList.find(c => c.code === value)?.dial_code}` : '',
                                                        MenuProps: {
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: '20rem',
                                                                    maxWidth: '20rem',
                                                                    minWidth: '20rem',
                                                                    minHeight: '20rem',
                                                                },
                                                            },
                                                        }
                                                    }}

                                                >
                                                    <MenuItem style={{
                                                        backgroundColor: 'var(--white)',
                                                    }}>
                                                        <TextField
                                                            autoFocus
                                                            placeholder="Search country"
                                                            fullWidth
                                                            value={searchCountry}
                                                            onChange={(e) => setSearchCountry(e.target.value)}
                                                            onClick={(e) => e.stopPropagation()}
                                                        />
                                                    </MenuItem>
                                                    {filteredCountries.length ? filteredCountries.map((countryDetails) => (
                                                        <MenuItem key={countryDetails.code} value={countryDetails.code} >
                                                            {countryDetails.display_name}
                                                        </MenuItem>
                                                    )) :
                                                        <MenuItem disabled>
                                                            Not Available
                                                        </MenuItem>}
                                                </OnboardingTextField>
                                            </FormControl>
                                            <FormControl style={{ width: '100%' }}>
                                                <OnboardingTextField
                                                    label="Phone"
                                                    type="number"
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    error={formError && !phone}
                                                    helperText={formError && !phone && 'Phone is required'}
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.updateProfileContainer}>
                                        <OnboardingButton
                                            type="submit"
                                            variant="contained"
                                            disabled={isLoading}
                                            style={{ fontWeight: '600', height: '3.5rem' }}
                                        >
                                            Update Profile
                                        </OnboardingButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default ProfileForm;