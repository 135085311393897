import TextField from '@mui/material/TextField';
import { OnboardingTextField } from '../onboarding/onboarding';
import styles from './countrySelect.module.css';
import CountryList from '../../lib/CountryList.json';
import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  countryCode: string;
  setCountry: (country: string) => void;
  fieldsToShow: string[];
  showError: boolean;
}

export default function CountrySelect(props: IProps) {
  const { countryCode, showError } = props;

  const [searchCountry, setSearchCountry] = useState('');

  const filteredCountries = CountryList.filter(country =>
    country.name.toLowerCase().includes(searchCountry.toLowerCase())
  ) || [];

  const [selectedItem, setSelectedItem] = useState({});

  const setCountry = (value) => {
    const selectedCountry = CountryList.find(c => c.code === value) || {};
    setSelectedItem(selectedCountry);
    props.setCountry(value);
  }

  useEffect(() => {
    if (countryCode) {
      setSelectedItem(CountryList.find(c => c.code === countryCode) || {});
    }
  }, [countryCode]);

  return (
    <OnboardingTextField
      select
      label="Country"
      value={countryCode}
      onChange={(e) => setCountry(e.target.value)}
      error={showError && !countryCode}
      helperText={showError && !countryCode ? 'Country is required' : ''}
      variant="standard"
      className={styles.countryDropdown}
      placeholder='Country'
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '20rem',
              minHeight: '20rem',
            },
          },
        }
      }}
    >
      <MenuItem style={{
        backgroundColor: 'var(--white)',
      }}>
        <TextField
          autoFocus
          placeholder="Search country"
          fullWidth
          value={searchCountry}
          onChange={(e) => setSearchCountry(e.target.value)}
          onClick={(e) => e.stopPropagation()}
        />
      </MenuItem>
        {filteredCountries.length ? filteredCountries.map((countryDetails) => (
          <MenuItem key={countryDetails.code} value={countryDetails.code}>
            {countryDetails.display_name}
          </MenuItem>
        )) :
          <MenuItem disabled>
            Not Available
          </MenuItem>
        }
    </OnboardingTextField>)
}
