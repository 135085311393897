import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { DropdownWrapperProps } from './types';
import { capitalizeWords } from 'src/lib/helper';

const MenuItemProfile = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  user-select: none;
  &:last-of-type {
    border-bottom: none;
  }
  color: var(--lightTextColor);
  cursor: pointer;
  &:hover {
    color: var(--primaryColor);
    background: var(--offWhite);
  };
  `,
);

const MenuButtonStyled = styled(BaseMenuButton)(
  ({ theme }) => `
  display:flex;
  background:transparent;
  align-items:center;
  gap:10px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.8;
  transition: all 150ms ease;
  cursor: pointer;
  font-family: 'Manrope';
  border: none;
  `,
);
const Listbox = styled('ul')(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 10px 0px;
  margin: 5px 0;
  min-width: 200px;
  overflow: auto;
  outline: 0px;
  z-index: 1;
  `,
);

const DropdownWrapper = ({ name, avatar, items }: DropdownWrapperProps) => {
  const getInitials = (name) => {
    const names = name.split(' ');
    return names
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Dropdown>
      <MenuButtonStyled>
        {avatar && (
          <Avatar>
            <img src={avatar} alt="Avatar" />
          </Avatar>
        )}
        {!avatar && (
          <Avatar className='avatar'>
            {getInitials(name)}
          </Avatar>
        )}

        <div className="profile-name">{capitalizeWords(name)}</div>

        <KeyboardArrowDownIcon className='key-down-arrow'/>
      </MenuButtonStyled>
      <Menu slots={{ listbox: Listbox }} className='menu'>
        {items.map((item) => (
          <MenuItemProfile key={item.name} onClick={item.onClick} className='menu-item'>
            {item.name}
          </MenuItemProfile>
        ))}
      </Menu>
    </Dropdown>
  );
};

export default DropdownWrapper;
