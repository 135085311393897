import React, { useRef } from 'react';
import styles from './forgot-password.module.css';
import { FormControl, Grid } from '@mui/material';
import { login } from '../../api/login-api';
import { useNavigate } from 'react-router-dom';
import {
    OnboardingLayout,
    OnboardingButton,
    OnboardingTextField,
} from '../onboarding/onboarding';
import { AuthContext } from './auth-provider';
import { useNotification } from '../notification/notification-provider';
import Logo from '../common/logo';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { resetPasswordLinkSend } from 'src/api/users';

// import Box from '@mui/material';
function ForgotPassword() {
    const formRef = useRef();
    const [email, setEmail] = React.useState('');
    const [formError, setFormError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    const { showNotification } = useNotification();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!email) {
            setFormError(true);
            setIsLoading(false);
            return;
        }

        // Submit form data to backend
        try {
            const response = await resetPasswordLinkSend({email});
            showNotification('Check Email For Reset Link', 'success');
            navigate('/organisation-list');
        } catch (error: any) {
            showNotification(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.wrapper}>
            <OnboardingLayout
                formHeight="72%"
                jsx={
                    <div className={styles.formContainer}>
                        <div className={styles.logoContainer}>
                            <Logo />
                        </div>
                        <div className={styles.formContent}>
                            <div className={styles.backBtn}>
                                <ArrowBackIosNewIcon
                                    fontSize="small"
                                    onClick={(e) => navigate('/login')}
                                />
                            </div>
                            <div className="login-text-container">
                                <h2 className={styles.forgotPasswordHeader}>Forgot Password</h2>
                                <p className="onboarding-sub-text">
                                    Please create a new password for your account
                                </p>
                            </div>
                            <div>
                                <form ref={formRef} onSubmit={handleSubmit}>
                                    <Grid container spacing={{ lg: 4, xl: 8 }} className={styles.emailWrapper}>
                                        <Grid item xs={12}>
                                            <FormControl style={{ width: '100%' }}>
                                                <OnboardingTextField
                                                    label="Email address"
                                                    type="text"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    error={formError}
                                                    helperText={formError && 'Email is required'}
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <div className={styles.resetButton}>
                                        <OnboardingButton
                                            type="submit"
                                            variant="contained"
                                            disabled={isLoading}
                                            style={{ fontWeight: '600', height: '3.5rem' }}
                                        >
                                            Send reset request
                                        </OnboardingButton>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default ForgotPassword;
