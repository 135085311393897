import React from 'react';
import styles from './summary.module.css';
import { combineClassNames } from 'src/lib/helper';
import { InfoOutlined } from '@mui/icons-material';
import { OnboardingButton } from '../onboarding/onboarding';

const InstanceSummary = ({
    toolName,
    provider,
    region = '',
    plan,
    support,
    estimatedHourlyPrice,
    estimatedMonthlyPrice,
    toolLogo,
    cloudProviderLogo,
    onSubmitHandler,
    planDetails,
    currency
}) => {
    return (
        <div className={styles.rightWrapper}>
            <div className={styles.summaryCard}>
                <div className={styles.section}>
                    <h2 className={styles.sectionTitle}>Tool name</h2>
                    <div className={styles.toolNameWrapper}>
                        <img src={toolLogo} alt={`${toolName} logo`} className={styles.toolLogo} />
                        <span className={styles.toolName}>{toolName}</span>
                    </div>
                </div>

                <div className={combineClassNames(styles.providerWrapper, styles.section)}>
                    <div>
                        <h2 className={styles.sectionTitle}>Provider</h2>
                        <span className={styles.sectionSubTitle}>{provider}</span>
                    </div>
                    <div>
                        <img src={cloudProviderLogo} alt={`${provider} logo`} className={styles.providerLogo} />
                    </div>
                </div>
                <div className={styles.section}>
                    <h2 className={styles.sectionTitle}>Region</h2>
                    <div className={styles.sectionSubTitle}>{region}</div>
                </div>

                <div className={styles.section}>
                    <h2 className={styles.sectionTitle}>Plan</h2>
                    <div className={styles.sectionSubTitle}>{plan}</div>
                    <ul className={styles.planDetails}>
                    {planDetails?.map((detail, index) => (
                            <li key={index}>{detail}</li>
                    ))}
                    </ul>
                </div>
                <div className={styles.supportSection}>
                    <h2 className={styles.sectionTitle}>Support</h2>
                    <div className={styles.sectionSubTitle}>{support}</div>
                </div>
            </div>
            <div className={styles.priceSection}>
                <h2 className={styles.sectionTitle}>
                    <span>
                        Estimated Hourly Price
                    </span><span className={styles.infoIcon}><InfoOutlined /></span>
                </h2>
                <div className={styles.price}>{parseFloat(estimatedHourlyPrice || 0).toFixed(2)}<span className={styles.currrency}> {currency} </span></div>
                <div className={styles.buttonWrapper}>
                    <OnboardingButton variant="contained" onClick={onSubmitHandler}>Submit</OnboardingButton>
                </div>

                <p className={styles.estimatedMonthly}>
                    Estimated monthly price is {parseFloat(estimatedMonthlyPrice || 0).toFixed(2)} {currency} based on 730 hours of usage
                </p>
            </div>
        </div>
    );
};

export default InstanceSummary;