import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getErrorMessage } from './helper';
import apiProvider from './provider';

export const login = async (email: string, password: string) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + API_ENDPOINTS.AUTH.LOGIN,
      data: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: () => true,
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
