import * as config from '../config';
import { API_ENDPOINTS } from './apiEndpoints';
import { getErrorMessage } from './helper';
import apiProvider from './provider';

export const signup = async (email: string, password: string, otp: string) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + API_ENDPOINTS.AUTH.SIGNUP,
      data: JSON.stringify({ email, password, otp }),
      headers: {
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return data?.data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const preSignup = async () => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.AUTH.PRESIGNUP,
      headers: {
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    throw error;
  }
};

export const sendSignupOTP = async (email) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + API_ENDPOINTS.AUTH.VERIFICATION,
      data: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
      
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
