import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import './breadcrumb.css';

interface BreadcrumbsProps {
  homeLabel?: string;
  path: {label: string, link: string}[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ path = [] }) => {

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" className='breakcrumb-wrapper'>
      {path.map((value, index) => {
        const last = index === path.length - 1;

        return last ? (
          <Typography color="text.primary" key={value.link} className='last-item'>
            {value.label}
          </Typography>
        ) : (
          <Link component={RouterLink} color="inherit" to={value.link} key={value.link} className='link'>
            {value.label}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;