import * as config from '../config';
import { getAuthenticatedUserHeaders, getErrorMessage } from './helper';
import { API_ENDPOINTS } from './apiEndpoints';
import apiProvider from './provider';

export const fetchSupportedToolsApi = async (params?) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url:
        config.coreBackendUrl +
        `${API_ENDPOINTS.SUPPORTED_TOOLS.GET_ALL}`,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      params
    });

    if (response.status === 200) {
      const data = response.data?.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const fetchSupportedToolsActionsApi = async (id: string) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url:
        config.coreBackendUrl +
        `${API_ENDPOINTS.SUPPORTED_TOOLS.FETCH_ACTIONS}`,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      params: {
        id
      },
      
    });

    if (response.status === 200) {
      const data = response.data?.actions;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
