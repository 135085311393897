export const generatePrettyName = (name: string): string => {
    return name
        .split(/(?=[A-Z])/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export const getInitials = (name, lastname) => {
    return `${name?.[0]?.toUpperCase() || ''}${lastname?.[0]?.toUpperCase() || ''}`;
};

export const parseDate = (date: string) => {
    const parsedDate = new Date(Number(date));
    return parsedDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true });
};

export const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const combineClassNames = (...classNames: string[]) => {
    return classNames.join(' ');
}