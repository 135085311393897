import * as config from '../config';
import { getAuthenticatedUserHeaders, getErrorMessage } from './helper';
import { API_ENDPOINTS } from './apiEndpoints';
import apiProvider from './provider';

export const organisationListApi = async () => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.ORGANIZATION.LIST,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const organisationCreateApi = async (organisationName: string) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + API_ENDPOINTS.ORGANIZATION.CREATE,
      data: JSON.stringify({ organisation_name: organisationName }),
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};
