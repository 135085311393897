import React from "react";
import { AuthContext } from "../login/auth-provider";
import { fetchCurrentUserDetails } from "src/api/users";
import { Navigate, Outlet } from "react-router-dom";


const ProtectedRoute = () => {
    const { isUserAuthenticated: checkIfUserIsAuthenticated, setUserDetails } = React.useContext(AuthContext);
    
    const isUserAuthenticated = checkIfUserIsAuthenticated();
    React.useEffect(() => {
      const getUserDetails = async () => {
        if (isUserAuthenticated) {
            const userDetails = await fetchCurrentUserDetails();
            setUserDetails(userDetails);
        }
      };
      getUserDetails();
    }, [isUserAuthenticated]);
  
    if (!isUserAuthenticated) {
      return <Navigate to="/login" state={{ from: location.pathname }} replace />;
    }
    return <Outlet />; 
  };

export default ProtectedRoute;