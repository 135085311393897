import Dashboard from '../common/dashboard';
import './managed-tools-dashboard.css';
import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { InputAdornment, Tabs as MuiTabs, Tab } from '@mui/material';
import {
  AccountBalanceWalletOutlined,
  PaymentsOutlined,
  ImportContactsOutlined,
  GroupOutlined,
  TuneOutlined,
  SpaceDashboardOutlined,
  BuildOutlined,
  Search,
} from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { managedToolsApi } from '../../api/managed-tools-api';
import { withAuthAndOrganisation } from '../login/auth-provider';
import { ToolData, SidebarItem } from './types';
import { styled } from '@mui/system';
import Breadcrumbs from '../common/BreadCrumbs';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const StyledTabsWrapper = styled(Tab)(({ theme }) => ({
  color: 'var(--lightTextColor)',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '600',
  textTransform: 'none',
  letterSpacing: '-0.01rem',
  '&.Mui-selected': {
    color: 'var(--darkTextColor)',
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '48px', // Set the desired height
    padding: '10px 14px', // Adjust padding as needed
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px', // Center the content vertically
  },
  '& .MuiInputBase-input': {
    height: '80px', // Set the height of the input area
    padding: '0', // Remove additional padding
    boxSizing: 'border-box',
  },
  '& .MuiInputLabel-root': {
    lineHeight: '1.8',
  },
  '.css-1nd7k79-MuiFormControl-root-MuiTextField-root ': {
    width: '100vw',
    background: 'red',
  },
}));
const ManagedToolsDashboard = withAuthAndOrganisation(function () {
  const [toolsData, setToolsData] = useState<ToolData[]>([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<string>('All');
  const tabs = ['All', 'Active', 'Applications', 'Development'];
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await managedToolsApi(activeTab);
        setLoading(false);
        setToolsData(data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [activeTab]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const sidebarItems: SidebarItem[] = [
    { icon: SpaceDashboardOutlined, label: 'Dashboard', link: '/' },
    { icon: BuildOutlined, label: 'Tools', link: '/settings' },
    { icon: GroupOutlined, label: 'User', link: '/profile' },
    {
      icon: AccountBalanceWalletOutlined,
      label: 'Billing',
      link: '/notifications',
    },
    {
      icon: PaymentsOutlined,
      label: 'Audit logs',
      link: '/notifications',
    },
    {
      icon: ImportContactsOutlined,
      label: 'Finance',
      link: '/notifications',
    },
    {
      icon: TuneOutlined,
      label: 'Project Settings',
      link: '/notifications',
    },
  ];

  return (
    <>
      <Dashboard
        sidebarItems={sidebarItems}
        contentJsx={
          <div className='dashboard-wrapper'>
            {/* <Breadcrumbs path={[
              {
                label: 'Tools',
                link: '/'
              }
            ]}/> */}
            <div className="dashboard-heading">
              <div className="dashboard-header-lower-text">Tools</div>
            </div>
            <div className="search-bar-container">
              <TextField
                placeholder='Search'
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search className='search-icon' />
                    </InputAdornment>
                  ),
                }}
                className='search-input'
              />
            </div>
            <div className="tabbed-interface">
              <MuiTabs
                value={activeTab}
                onChange={handleChange}
                aria-label="tabs example"
                className="tabs-list"
                TabIndicatorProps={{
                  style: {
                    background: '#4B4EFC',
                    color: 'black',
                  },
                }}>
                {tabs.map((tab) => (
                  <StyledTabsWrapper label={tab} value={tab} key={tab} />
                ))}
              </MuiTabs>
              <div className="tab-content">
                {toolsData.map((tool, index) => (
                  <Link className="card" to={`/tool/${tool.name}`}>
                    <CardHeader
                      avatar={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={tool.logo}
                            alt="Kafka Logo"
                            className="logo"
                          />
                        )
                      }
                      title={
                        loading ? (
                          <Skeleton
                            animation="wave"
                            height={20}
                            width={100}
                            style={{ marginBottom: 6 }}
                          />
                        ) : (
                          <h2 className="services-card-title">{tool.name}</h2>
                        )
                      }
                      action={
                        loading ? (
                          <Skeleton animation="wave" height={30} width={100} />
                        ) : (
                          <button
                            className={tool.active ? 'active' : 'inactive'}>
                            {tool.active ? 'Active' : 'Inactive'}
                          </button>
                        )
                      }
                    />
                    <CardContent className='tool-description'>
                      {loading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 5 }}
                            width="90%"
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 5 }}
                            width="90%"
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 5 }}
                            width="90%"
                          />
                          <Skeleton animation="wave" height={10} width="70%" />
                        </>
                      ) : (<>
                        {tool.description}
                      </>
                      )}
                    </CardContent>
                    <CardActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }} className='card-action'>
                      {loading ? (
                        <Skeleton animation="wave" height={30} width={100} />
                      ) : (
                        <button onClick={tool.onGuidesClick} className="guides">
                          <span>Guides</span><NorthEastIcon className='ne-icon' />
                        </button>
                      )}
                      {loading ? (
                        <Skeleton animation="wave" height={30} width={100} />
                      ) : (
                        <div className="icon-deploy-wrapper">
                          <Link to={`/tool/${tool.name}`} className="deploy">
                              Deploy
                          </Link>
                          <ArrowForwardIcon className='arrow-forward' />
                        </div>
                      )}
                    </CardActions>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
});

export default ManagedToolsDashboard;
