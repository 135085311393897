import axios from 'axios';
import { authenticationHeadersPresent, clearUserCredential } from './helper';

const apiProvider = axios.create({});

// Add 401 interceptor
apiProvider.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    // Dispatch action that user is being force logged out
    // await dispatch(setForceLogout());
    if (authenticationHeadersPresent()) {
        clearUserCredential();
      const errorMessage = error.response?.data?.error?.message || 'User logged out';
      window.localStorage.setItem('login_page_error_message', errorMessage);
    }
    if ( window.location.pathname !== '/login' ){
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export default apiProvider;