import React from "react";
import { AuthContext } from "../login/auth-provider";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute: React.FC = () => {
    const { isUserAuthenticated: checkIfUserIsAuthenticated } = React.useContext(AuthContext);
    const isUserAuthenticated = checkIfUserIsAuthenticated();
    if (isUserAuthenticated) {
      return <Navigate to="/" replace />;
    }
  
    return <Outlet />;
  };

export default PublicRoute;