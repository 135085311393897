import * as React from 'react';
import { CardContent, Typography, Grid, Card, Box, Stack } from '@mui/material';
import { ToolInstance } from './types';
import styles from './instanceDetails.module.css';

function InstanceDetail({ title, logoUrl, config, rightSideComponent }: ToolInstance) {
  return (
    <Card className={styles.instanceCard}>
      <CardContent className={styles.cardContent}>
        <Grid container spacing={2} className={styles.gridContainer}>
          <Grid item className={styles.infoContainer}>
            <div className={styles.title}>
              {title}
            </div>
            <div className={styles.configStack}>
              {config.map((configItem, index) => (
                <Typography variant="body2" key={index} className={styles.configItem}>
                  {index === 0 ? '' : '•'} {configItem}
                </Typography>
              ))}
            </div>
          </Grid>
          {
            logoUrl ?
              <Grid item sm={4} className={styles.logoContainer}>
                <img src={logoUrl} alt="Logo" className={styles.logo} />
              </Grid> : <></>
          }
          {
            rightSideComponent ? <Grid item sm={4} className={styles.rightSideComponent}>
              {rightSideComponent}
            </Grid> : <></>
          }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default InstanceDetail;