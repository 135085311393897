import * as config from '../config';
import { getAuthenticatedUserHeaders, getErrorMessage } from './helper';
import { API_ENDPOINTS } from './apiEndpoints';
import apiProvider from './provider';

export const managedToolsApi = async (activeTab: string) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url:
        config.coreBackendUrl +
        `${API_ENDPOINTS.MANAGED_TOOLS.GET_ALL}?tab=${activeTab}`,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      const errorMessage = response.data?.error?.message || 'Network error!';
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const getManagedToolsInstances = async (toolName: string) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url:
        config.coreBackendUrl + `${API_ENDPOINTS.MANAGED_TOOLS.GET_INSTANCES}?name=${toolName}`,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const getSupportedCloudProviders = async () => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.CLOUD_MANAGEMENT.GET_SUPPORTED_CLOUD_PROVIDERS,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const getSupportedVirtualMachine = async ({region,provider}) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.CLOUD_MANAGEMENT.GET_SUPPORTED_VIRTUAL_MACHINES,
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      params: {
        region,
        provider
      }
    });
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}

export const getSupportedSupport = async ({toolName}) => {
  try {
    const response = await apiProvider({
      method: 'get',
      url: config.coreBackendUrl + API_ENDPOINTS.CLOUD_MANAGEMENT.GET_SUPPORT,
      params: {
        name: toolName
      },
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch(error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

export const managedToolDeploy = async (data) => {
  try {
    const response = await apiProvider({
      method: 'post',
      url: config.coreBackendUrl + '/api/managedTools/deploy',
      headers: {
        ...getAuthenticatedUserHeaders(),
        'Content-Type': 'application/json',
      },
      data
    });
    return response.data;
  } catch(error) {
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
}